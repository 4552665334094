<template>
  <div class="row">
    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <strong>Data Pemohon</strong>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <span>Nomor Registrasi</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
              <a-select
                v-if="pinginubah"
                show-search
                label-in-value
                id="inputdatapemohonnomor_registrasi"
                v-model="input.datapemohon.nomor_registrasi"
                placeholder="Nomor registrasi pemohon"
                style="width: 100%;"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="fetchUser"
                @change="handleChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in data" :key="d.kode">
                  {{ d.kode + "-" + d.nama }}
                </a-select-option>
              </a-select>
              <a-input
                :readOnly="true"
                :value="input.datapemohon.nomor_registrasi.key"
                @click="
                  datanasabah !== null
                    ? (pinginubah = false)
                    : (pinginubah = true)
                "
                v-else
              ></a-input>
              <!-- <a-select
                show-search
                label-in-values
                v-model="input.datapemohon.nomor_registrasi"
                placeholder="Nomor registrasi"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="handleSearch"
                @change="pilihPemohon"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in datapemohon" :key="d.id">
                  {{ d.kode + ' - ' + d.nama }}
                </a-select-option>
              </a-select> -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Tanggal Permohonan</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-date-picker
                id="inputdatapemohontanggal_permohonan"
                style="width: 120px;"
                format="DD-MM-YYYY"
                disabled
                v-model="input.datapemohon.tanggal_permohonan"
              />
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Jumlah Kredit</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <cleave
                id="inputdatapemohonjumlah_kredit"
                :options="options.number"
                v-model="input.datapemohon.jumlah_kredit"
                placeholder="Jumlah kredit pemohon"
                class="ant-input"
                :readOnly="true"
                style="cursor: not-allowed;"
              />
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Jangka Waktu</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                id="inputdatapemohonjangka_waktu"
                class=""
                v-model="input.datapemohon.jangka_waktu"
                placeholder="Jangka waktu"
                :readOnly="true"
                style="cursor: not-allowed;"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Nama</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                class=""
                v-model="input.datapemohon.nama"
                placeholder="Nama pemohon"
                :readOnly="true"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Alamat</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                class=""
                v-model="input.datapemohon.alamat"
                placeholder="Alamat pemohon"
                :readOnly="true"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Jenis Pekerjaan</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                class=""
                v-model="input.datapemohon.jenis_pekerjaan"
                placeholder="Jenis pekerjaan pemohon"
                :readOnly="true"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Nama Perusahaan</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                class=""
                v-model="input.datapemohon.nama_perusahaan"
                placeholder="Nama perusahaan pemohon"
                :readOnly="true"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <a-divider />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <strong>Penilaian</strong>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <span @click="showModal" style="cursor: pointer;"
                >Nilai Pasar</span
              >
              <a-icon
                type="table"
                class="ml-2 text-primary"
                @click="showModal"
              />
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
              <div class="row">
                <a-input
                  id="inputfortablepenilaianketerangan"
                  v-model="input.fortable.keterangan"
                  placeholder="Sumber"
                ></a-input>
              </div>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <span> </span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <div class="row">
                <cleave
                  id="inputfortablepenilaianvalue"
                  v-model="input.fortable.value"
                  :options="options.number"
                  placeholder="Nilai"
                  class="ant-input col-md-9"
                  v-on:keydown.native.enter="tambahnilaipasar"
                />
                <a-button
                  id="simpannilaipasar"
                  class="btn btn-outline-primary btn-sm col-md-3"
                  @click="tambahnilaipasar"
                  >{{
                    input.fortable.jenis !== "" && input.fortable.value !== ""
                      ? "Tambah"
                      : datatablenilaipasar.length + " Data"
                  }}</a-button
                >
              </div>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Nilai Rata-Rata Pasar</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <cleave
                :options="options.number"
                v-model="input.penilaian.nilai_rata_pasar"
                placeholder="Penilaian nilai rata-rata pasar"
                class="ant-input"
              />
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Penyusutan</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                class=""
                v-model="input.penilaian.penyusutan"
                placeholder="Penyusutan"
                style="width: 120px;"
                suffix="%"
                @change="penyusutan"
                :readOnly="false"
              ></a-input>
              <!-- <a-select
                placeholder="Penyusutan"
                style="width: 120px;"
                v-model="input.datapemohon.penyusutan"
              >
                <a-icon slot="suffixIcon" type="percentage" />
              </a-select> -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <strong>Nilai Taksasi</strong>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <cleave
                :options="options.number"
                v-model="input.penilaian.nilai_taksasi"
                placeholder="Nilai Taksasi"
                class="ant-input"
              />
              <!-- style="cursor: not-allowed;" -->
            </div>
            <a-divider />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <strong>Kesimpulan</strong>
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-1">
              <span>Nilai Taksasi</span>
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
              <cleave
                :options="options.number"
                v-model="input.kesimpulan.nilai_taksasi"
                @change="countKesimpulan"
                placeholder="Nilai taksasi"
                class="ant-input"
              />
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2">
              <span>Presentase Plafond Kredit</span>
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1">
              <a-input
                class=""
                id="inputkesimpulanpresentase"
                v-model="input.kesimpulan.presentase_plafond_kredit"
                placeholder="Percentage"
                @change="countKesimpulan"
                style="width: 50%;"
                suffix="%"
              ></a-input>
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2">
              <strong>Maksimal Plafond Kredit</strong>
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1">
              <cleave
                :options="options.number"
                v-model="input.kesimpulan.maksimal_plafond_kredit"
                placeholder="Maks plafond kredit"
                class="ant-input"
              />
              <!-- <label>Nilai Asli: 941,453.15</label> -->
              <!-- style="cursor: not-allowed;" -->
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
              <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                  <strong>Kode Jaminan</strong>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <a-select
                    placeholder="01"
                    style="width: 100%;"
                    v-model="input.kode_jaminan.kode"
                  >
                    <!-- <a-icon slot="suffixIcon" type="down" /> -->
                  </a-select>
                </div>
                <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                  <a-input
                    class=""
                    v-model="input.kode_jaminan.keterangan"
                    placeholder=""
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
              <a-button class="btn btn-outline-danger float-right" @click="back"
                >Return</a-button
              >
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="row mt-4">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Nomor Taksasi</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    class=""
                    id="inputnomortaksasi"
                    v-model="input.nomor_taksasi"
                    placeholder="nomor taksasi"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                  <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <span>STNK</span>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-date-picker
                        :default-value="moment()"
                        format="DD-MM-YYYY"
                        v-model="input.stnk"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a-divider />
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2">
              <strong>Data Kendaraan</strong>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span style="margin-top: 10px;">Nomor BPKB</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                placeholder="Nomor BPKB"
                id="inputkendaraannomorbpkb"
                v-model="input.datakendaraan.nomor_bpkb"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Atas Nama di BPKB</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                id="inputkendaraanatasnamabpkb"
                v-model="input.datakendaraan.atas_nama_di_bpkb"
                placeholder="Atas Nama di BPKB"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Nama Pemilik Sekarang</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                id="inputkendaraannamapemiliksekarang"
                v-model="input.datakendaraan.nama_pemilik_sekarang"
                placeholder="Nama Pemilik Sekarang"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Alamat Sekarang</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                id="inputkendaraanalamatsekarang"
                v-model="input.datakendaraan.alamat_sekarang"
                placeholder="Alamat sekarang"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
                  <span>No. Polisi</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                  <a-input
                    class=""
                    id="inputkendaraannopol"
                    v-model="input.datakendaraan.no_polisi"
                    placeholder="No. polisi kendaraan"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                  style="padding-right: unset;"
                >
                  <span>Nomor Rangka</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                  <a-input
                    class=""
                    id="inputkendaraannorangka"
                    v-model="input.datakendaraan.nomor_rangka"
                    placeholder="No. rangka kendaraan"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                  <span>Merk</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    class=""
                    id="inputkendaraanmerk"
                    v-model="input.datakendaraan.merk"
                    placeholder="Contoh: Honda, Mitsubishi, Yamaha"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                  style="padding-right: unset;"
                >
                  <span>Nomor Mesin</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    class=""
                    id="inputkendaraannomesin"
                    v-model="input.datakendaraan.nomor_mesin"
                    placeholder="Nomor mesin"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                  <span>Jenis</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-select
                    v-model="input.datakendaraan.jenis"
                    id="inputkendaraanjenis"
                    placeholder="Jenis kendaraan"
                    style="width: 100%;"
                  >
                    <a-select-option value="Sepeda Motor"
                      >Sepeda Motor</a-select-option
                    >
                    <a-select-option value="Mobil">Mobil</a-select-option>
                    <a-select-option value="Mini Bus">Mini Bus</a-select-option>
                    <a-select-option value="Bus">Bus</a-select-option>
                  </a-select>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                  style="padding-right: unset;"
                >
                  <span>Bahan Bakar</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-select
                    v-model="input.datakendaraan.bahan_bakar"
                    id="inputkendaraanbahanbakar"
                    placeholder="Bahan bakar kendaraan"
                    style="width: 100%;"
                  >
                    <a-select-option value="Bensin">Bensin</a-select-option>
                    <a-select-option value="Solar">Solar</a-select-option>
                    <a-select-option value="Avgar">Avgar</a-select-option>
                    <a-select-option value="Gas">Gas</a-select-option>
                    <a-select-option value="Listrik">Listrik</a-select-option>
                  </a-select>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                  <span>Type</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    class=""
                    id="inputkendaraantipe"
                    v-model="input.datakendaraan.type"
                    placeholder="Jazz, Xpander, Mio"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                  style="padding-right: unset;"
                >
                  <span>Warna</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    class=""
                    id="inputkendaraanwarna"
                    v-model="input.datakendaraan.warna"
                    placeholder="Warna kendaraan"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Tahun pembuatan</span>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <a-input
                    class=""
                    id="inputkendaraantahunpembuatan"
                    v-model="input.datakendaraan.tahun_pembuatan"
                    placeholder="Tahun kendaraan"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                  style="padding-right: unset;"
                >
                  <span>Perlengkapan</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    class=""
                    id="inputkendaraanperlengkapan"
                    v-model="input.datakendaraan.perlengkapan"
                    placeholder="Perlengkapan kendaraan"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                  <span>Isi Silinder</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    class=""
                    id="inputkendaraanissiilinder"
                    v-model="input.datakendaraan.isi_silinder"
                    placeholder="Silinder kendaraan"
                    :readOnly="false"
                    suffix="CC"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                  style="padding-right: unset;"
                >
                  <span>Asuransi</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    class=""
                    id="inputkendaraanasuransi"
                    v-model="input.datakendaraan.asuransi"
                    placeholder="Asuransi kendaraan"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                  <span>Jumlah Roda</span>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <cleave
                    id="inputkendaraanjmlroda"
                    :options="options.number"
                    class="ant-input"
                    v-model="input.datakendaraan.jumlah_roda"
                    placeholder="Roda"
                  />
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                  style="padding-right: unset;"
                >
                  <span>Atas nama</span>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <a-select
                    placeholder="Atas nama siapa?"
                    style="width: 100%;"
                    v-model="input.datakendaraan.atas_nama"
                  >
                    <a-select-option value="Atas nama sendiri">
                      Atas nama sendiri
                    </a-select-option>
                    <a-select-option
                      value="Atas nama keluarga dalam 1 kartu keluarga"
                    >
                      Atas nama keluarga dalam 1 kartu keluarga
                    </a-select-option>
                    <a-select-option value="Atas nama orang lain">
                      Atas nama orang lain
                    </a-select-option>
                  </a-select>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Kondisi Kendaraan</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                id="inputkendaraankondisi"
                v-model="input.datakendaraan.kondisi_kendaraan"
                placeholder="Example: Bagus, Terawat, dan Tidak lecet sama sekali"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Notaris</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-select
                placeholder="Notaris"
                style="width: 50%;"
                v-model="input.datakendaraan.notaris"
              >
                <a-select-option value="Pengikatan Internal">
                  Pengikatan Internal
                </a-select-option>
                <a-select-option value="Pengikatan Fiducia">
                  Pengikatan Fiducia
                </a-select-option>
              </a-select>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <a-divider />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <strong>Analisa Yuridis/Legalitas</strong>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <span>Analisa</span>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <a-input
                class=""
                id="inputanalisa"
                v-model="input.analisa_yuridis.analisa"
                placeholder="Ex: Surat-surat kendaraan berupa STNK dan pajak masih berlaku, BPKB lengkap. Kendaraan atas nama debitur"
                :readOnly="false"
              ></a-input>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2"> -->
              <!-- <a-button class="btn btn-outline-success mr-2 float-right">Cetak Perjanjian</a-button> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="card">
        <div class="card-header">
          <strong>Upload</strong>
          <strong v-if="action === 'update'">
            (*Untuk foto jika tidak ingin merubah biarkan kosong)</strong
          >
              <a-button
                :loading="loading"
                id="therealsimpanbutton"
                class="btn btn-outline-primary col-xs-2 col-sm-2 col-md-2 col-lg-2 float-right"
                @click="validasi"
                >Simpan</a-button
              >
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                  placeholder="Foto Halaman Identitas Pemilik"
                  ref="bpkbpemilik"
                  @click="selectFile('halamanIdentitasPemilik')"
                ></a-input>
              </center>
              <input
                type="file"
                style="display: none;"
                ref="halamanIdentitasPemilik"
                accept="image/*"
                @change="(e) => previewImage(e, 'bpkbpemilik')"
              />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                  placeholder="Foto Halaman Identitas Kendaraan"
                  ref="bpkbkendaraan"
                  @click="selectFile('halamanIdentitasKendaraan')"
                >
                </a-input>
              </center>
              <!-- <a-icon type="close" class="text-danger" @click=""/> -->
              <input
                type="file"
                style="display: none;"
                ref="halamanIdentitasKendaraan"
                accept="image/*"
                @change="(e) => previewImage(e, 'bpkbkendaraan')"
              />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
              <center>
                <a-card style="width: 50%;">
                  <div style="cursor: pointer;">
                    <img
                      :src="image.bpkbpemilik"
                      style="width: 100%; cursor: pointer;"
                      alt="Halaman BPKB Pemilik"
                      v-if="image.bpkbpemilik !== null"
                      @click="
                        lihatgambar('Halaman BPKB Pemilik', image.bpkbpemilik)
                      "
                    />
                    <a-icon
                      type="file-image"
                      style="font-size: 18px;"
                      @click="selectFile('halamanIdentitasPemilik')"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
              <center>
                <a-card style="width: 50%;">
                  <div>
                    <img
                      :src="image.bpkbkendaraan"
                      alt="Halaman BPKB Kendaraan"
                      style="width: 100%; cursor: pointer;"
                      v-if="image.bpkbkendaraan !== null"
                      @click="
                        lihatgambar(
                          'Halaman BPKB Kendaraan',
                          image.bpkbkendaraan
                        )
                      "
                    /><a-icon
                      @click="selectFile('halamanIdentitasKendaraan')"
                      type="file-image"
                      style="font-size: 18px;"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                  placeholder="Tampak depan"
                  ref="jaminandepan"
                  @click="selectFile('jaminanKendaraanTampakDepan')"
                ></a-input>
              </center>
              <input
                type="file"
                style="display: none;"
                ref="jaminanKendaraanTampakDepan"
                accept="image/*"
                @change="(e) => previewImage(e, 'jaminandepan')"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                  placeholder="Tampak samping kanan"
                  ref="jaminankanan"
                  @click="selectFile('jaminanKendaraanTampaksampingkanan')"
                >
                  <!-- <a-icon type="close" class="text-danger" @click=""/> -->
                </a-input>
              </center>
              <input
                type="file"
                style="display: none;"
                ref="jaminanKendaraanTampaksampingkanan"
                accept="image/*"
                @change="(e) => previewImage(e, 'jaminankanan')"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                  placeholder="Tampak samping kiri"
                  ref="jaminankiri"
                  @click="selectFile('jaminanKendaraanTampaksampingkiri')"
                >
                  <!-- <a-icon type="close" class="text-danger" @click=""/> -->
                </a-input>
              </center>
              <input
                type="file"
                style="display: none;"
                ref="jaminanKendaraanTampaksampingkiri"
                accept="image/*"
                @change="(e) => previewImage(e, 'jaminankiri')"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                  placeholder="Tampak samping belakang"
                  ref="jaminanbelakang"
                  @click="selectFile('jaminanKendaraanTampaksampingbelakang')"
                >
                  <!-- <a-icon type="close" class="text-danger" @click=""/> -->
                </a-input>
              </center>
              <input
                type="file"
                style="display: none;"
                ref="jaminanKendaraanTampaksampingbelakang"
                accept="image/*"
                @change="(e) => previewImage(e, 'jaminanbelakang')"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <center>
                <a-card style="width: 82%;">
                  <div>
                    <img
                      :src="image.jaminandepan"
                      alt="Jaminan kendaraan depan"
                      style="width: 100%; cursor: pointer;"
                      v-if="image.jaminandepan !== null"
                      @click="
                        lihatgambar(
                          'Jaminan kendaraan depan',
                          image.jaminandepan
                        )
                      "
                    />
                    <a-icon
                      type="file-image"
                      style="font-size: 18px;"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <center>
                <a-card style="width: 82%;">
                  <div>
                    <img
                      :src="image.jaminankanan"
                      alt="Jaminan kendaraan kanan"
                      style="width: 100%; cursor: pointer;"
                      v-if="image.jaminankanan !== null"
                      @click="
                        lihatgambar(
                          'Jaminan kendaraan kanan',
                          image.jaminankanan
                        )
                      "
                    />
                    <a-icon
                      type="file-image"
                      style="font-size: 18px;"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <center>
                <a-card style="width: 82%;">
                  <div>
                    <img
                      :src="image.jaminankiri"
                      alt="Jaminan kendaraan kiri"
                      style="width: 100%; cursor: pointer;"
                      v-if="image.jaminankiri !== null"
                      @click="
                        lihatgambar('Jaminan kendaraan kiri', image.jaminankiri)
                      "
                    />
                    <a-icon
                      type="file-image"
                      style="font-size: 18px;"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <center>
                <a-card style="width: 82%;">
                  <div>
                    <img
                      :src="image.jaminanbelakang"
                      alt="Jaminan kendaraan belakang"
                      style="width: 100%; cursor: pointer;"
                      v-if="image.jaminanbelakang !== null"
                      @click="
                        lihatgambar(
                          'Jaminan kendaraan belakang',
                          image.jaminanbelakang
                        )
                      "
                    />
                    <a-icon
                      type="file-image"
                      style="font-size: 18px;"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modaldetail ref="detailmodal" />
    <zoomgambar ref="zoomgambar" />
  </div>
</template>

<script>
import NProgress from 'nprogress'
import * as lou from '@/services/data/lou'
import modaldetail from '@/components/lou/temptable'
import { Modal } from 'ant-design-vue'
import debounce from 'lodash/debounce'
import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/storage'
// import { uuid } from 'vue-uuid'
import zoomgambar from '@/components/lou/zoomgambar.vue'

import Cleave from 'vue-cleave-component'

import Docxtemplater from 'docxtemplater'
import ImageModule from 'docxtemplater-image-module-free'
import PizZip from 'pizzip'
import { saveAs } from 'file-saver'
import PizZipUtils from 'pizzip/utils/index.js'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  components: {
    modaldetail,
    cleave: Cleave,
    zoomgambar,
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      pinginubah: false,
      datanasabah:
        localStorage.getItem('datanasabah') === undefined
          ? {}
          : JSON.parse(localStorage.getItem('datanasabah')),
      editdata: JSON.parse(localStorage.getItem('editformbpkb')),
      options: {
        number: {
          numeral: true,
        },
      },
      image: {
        bpkbpemilik: '',
        bpkbkendaraan: '',
        jaminandepan: '',
        jaminankanan: '',
        jaminankiri: '',
        jaminanbelakang: '',
      },
      input: {
        uploadbpkb: [],
        datapemohon: {
          nomor_registrasi: {},
          tanggal_permohonan: moment(),
          jumlah_kredit: '',
          jangka_waktu: '',
          nama: '',
          alamat: '',
          jenis_pekerjaan: '',
          nama_perusahaan: '',
        },
        penilaian: {
          nilai_pasar: '',
          nilai_rata_pasar: '',
          penyusutan: '2',
          nilai_taksasi: '',
        },
        kesimpulan: {
          nilai_taksasi: '',
          presentase_plafond_kredit: '',
          maksimal_plafond_kredit: '',
        },
        kode_jaminan: {
          kode: '01',
          keterangan: 'BPKB',
        },
        stnk: moment(),
        nomor_taksasi: '',
        datakendaraan: {
          nomor_bpkb: '',
          atas_nama_di_bpkb: '',
          nama_pemilik_sekarang: '',
          alamat_sekarang: '',
          no_polisi: '',
          nomor_rangka: '',
          merk: '',
          nomor_mesin: '',
          jenis: 'Sepeda Motor',
          bahan_bakar: 'Bensin',
          type: '',
          warna: '',
          tahun_pembuatan: '',
          perlengkapan: '',
          isi_silinder: '',
          asuransi: '',
          jumlah_roda: '',
          kondisi_kendaraan: '',
          atas_nama: 'Atas nama sendiri',
          notaris: 'Pengikatan Internal',
        },
        analisa_yuridis: {
          analisa: '',
        },
        fortable: {
          jenis: 'Informasi Pasar/Kendaraan',
          value: '',
          keterangan: '',
        },
      },
      action: '',
      title: '',
      data: [],
      datatablenilaipasar: [],
      fetching: false,
      datapemohon: [],
      urlGambar: [],
      datenow: '',
      loading: false,
    }
  },
  async created() {
    this.datenow = lou.datenow()
    if (this.datanasabah !== null) {
      if (this.datanasabah.action === 'create') {
        this.action = 'create'
        this.title = 'Tambah Data'
        this.input.datapemohon.nomor_registrasi.key = this.datanasabah.kode
        this.input.datapemohon.nama = this.datanasabah.nama
        this.input.datapemohon.alamat = this.datanasabah.alamat
        this.input.datapemohon.jenis_pekerjaan = this.datanasabah.pekerjaan
        this.input.datapemohon.nama_perusahaan = this.datanasabah.pekerjaan
        this.fetchUser(this.input.datapemohon.nomor_registrasi.key)
        this.handleChange(this.input.datapemohon.nomor_registrasi)
      } else {
        var target = this.datanasabah.target_jaminan
        this.action = 'update'
        this.title = 'Update Data'
        var response = await lou.customUrlGet2('taksasibpkb/' + target.id_jaminan)
        this.editdata = response.data
        this.setData()
        this.fetchUser(this.input.datapemohon.nomor_registrasi.key)
        this.handleChange(this.input.datapemohon.nomor_registrasi)
      }
    } else {
      if (this.editdata.id !== '') {
        this.action = 'update'
        this.title = 'Update Data'
        this.setData()
        this.fetchUser(this.input.datapemohon.nomor_registrasi.key)
        this.handleChange(this.input.datapemohon.nomor_registrasi)
      } else {
        this.pinginubah = true
        this.action = 'create'
        this.title = 'Tambah Data'
      }
    }
    // console.log('this.editdata.bpkbpemilik.indexOf', this.editdata.bpkbpemilik.indexOf('.jpg'))
  },
  watch: {
    datatablenilaipasar: {
      handler: function (val, oldVal) {
        this.countRataRata()
      },
      deep: true,
    },
  },
  methods: {
    moment,
    lihatgambar(caption, link) {
      var data = {
        imageUrl: link,
        caption: caption,
      }
      this.$refs.zoomgambar.lihatgambar(data)
    },
    validasi() {
      if (
        this.input.datapemohon.nomor_registrasi.key === undefined ||
        this.input.datapemohon.nomor_registrasi.key === ''
      ) {
        // console.log('error', 'Data nomor registrasi harus diisi!')
        lou.shownotif(
          'Validation',
          'Data nomor registrasi harus diisi!',
          'info',
        )
      } else {
        // if (this.action === 'create') {
        // if (this.input.uploadbpkb.length === 6) {
        this.tryUpload()
        this.loading = true
        // } else {
        // console.log('error', 'Foto harus lengkap!')
        // lou.shownotif('Validation', 'Foto harus lengkap!', 'info')
        // }
        // } else {
        //   this.loading = true
        //   this.tryUpload()
        // }
      }
    },
    find(value) {
      // console.log('value', value)
    },
    previewImage(event, ref) {
      var indexada = this.input.uploadbpkb.find((x) => x.from === ref)
      if (indexada === undefined) {
        this.input.uploadbpkb.push({
          from: ref,
          image: event.target.files[0],
        })
      } else {
        indexada.image = event.target.files[0]
      }
      this.$refs[ref].value = event.target.files[0].name
      this.image[ref] = URL.createObjectURL(event.target.files[0])
    },
    pilihPemohon(value) {
      this.value = value
      fetch(value, (data) => (this.data = data))
      // console.log('value', value)
    },
    tryUpload() {
      this.urlGambar = []
      // console.log('this.input.uploadbpkb', this.input.uploadbpkb)
      if (this.input.uploadbpkb.length === 0) {
        this.simpan()
      } else {
        var uuid = this.$uuid.v4().replace(/[^a-z\d\s]+/gi, '')
        if (this.action === 'update') {
          if (this.editdata.bpkbpemilik !== null) {
            if (this.editdata.bpkbpemilik.includes('firebasestorage')) {
              uuid = this.editdata.bpkbpemilik.substring(this.editdata.bpkbpemilik.indexOf('-') + 1, this.editdata.bpkbpemilik.indexOf('.png') < 0 ? this.editdata.bpkbpemilik.indexOf('.jpg') : this.editdata.bpkbpemilik.indexOf('.png'))
            }
          }
        }
        this.input.uploadbpkb.forEach(async (element) => {
          var type = element.type === 'image/jpeg' ? '.jpg' : '.png'
          await this.onUpload(
            '_' + this.input.datapemohon.nomor_registrasi.key + element.from + '-' + uuid + type,
            element.image,
            element,
          )
        })
      }
    },
    async simpan() {
      var listUniqId = this.datatablenilaipasar.map(({ key, ...item }) => item)
      // console.log('this.image.bpkbpemilik', this.image.bpkbpemilik)
      // console.log('this.input.kesimpulan', this.input.kesimpulan)
      var fd = {
        kode_pemohon: this.input.datapemohon.nomor_registrasi.key,
        nomorbpkb: this.input.datakendaraan.nomor_bpkb,
        tglberlakustnk: this.input.stnk.format('YYYY-MM-DD'),
        atasnamabpkb: this.input.datakendaraan.atas_nama_di_bpkb,
        namapemiliksekarang: this.input.datakendaraan.nama_pemilik_sekarang,
        alamatSekarang: this.input.datakendaraan.alamat_sekarang,
        nopolisi: this.input.datakendaraan.no_polisi,
        merk: this.input.datakendaraan.merk,
        jenis: this.input.datakendaraan.jenis,
        tipe: this.input.datakendaraan.type,
        tahunpembuatan: this.input.datakendaraan.tahun_pembuatan,
        isisilinder: this.input.datakendaraan.isi_silinder,
        jmlroda: this.input.datakendaraan.jumlah_roda,
        norangka: this.input.datakendaraan.nomor_rangka,
        nomesin: this.input.datakendaraan.nomor_mesin,
        bahanbakar: this.input.datakendaraan.bahan_bakar,
        warna: this.input.datakendaraan.warna,
        perlengkapan: this.input.datakendaraan.perlengkapan,
        asuransi: this.input.datakendaraan.asuransi,
        nomortaksasi: this.input.nomor_taksasi,
        atasnama: this.input.datakendaraan.atas_nama,
        notaris: this.input.datakendaraan.notaris,
        kondisikendaraan: this.input.datakendaraan.kondisi_kendaraan,
        penilaian: listUniqId,
        nilaipasarratarata: this.input.penilaian.nilai_rata_pasar,
        penyusutan: this.input.penilaian.penyusutan,
        nilaibarang: this.input.penilaian.nilai_taksasi,
        nilaitaksasi: this.input.kesimpulan.nilai_taksasi,
        presentaseplafondkredit: this.input.kesimpulan
          .presentase_plafond_kredit,
        maksimalplafondkredit: this.input.kesimpulan.maksimal_plafond_kredit,
        analisa: this.input.analisa_yuridis.analisa,
        bpkbpemilik: this.image.bpkbpemilik,
        bpkbkendaraan: this.image.bpkbkendaraan,
        jaminandepan: this.image.jaminandepan,
        jaminankanan: this.image.jaminankanan,
        jaminankiri: this.image.jaminankiri,
        jaminanbelakang: this.image.jaminanbelakang,
      }
      // console.log('fd', fd)
      var errordatapenilaian = []
      fd.penilaian.forEach((element, i) => {
        // console.log('element', element)
        // console.log('i', i)
        if (typeof element.value === 'string') {
          if (element.value.includes('.') || element.value.includes(',')) {
            errordatapenilaian.push(
              {
                baris: i + 1,
                value: element.value,
              },
            )
          }
        }
      })
      if (errordatapenilaian.length === 0) {
        // console.log('urlGambar', this.urlGambar)
        if (this.urlGambar.length !== 0) {
          this.urlGambar.forEach((element) => {
            fd[element.key] = element.link
          })
        }
        // console.log('fd', fd)
        var res
        if (this.action === 'create') {
          res = await lou.customUrlPost2('taksasibpkb', fd)
        } else {
          fd.id = this.editdata.id
          res = await lou.customUrlPut2('taksasibpkb', fd)
        }
        if (res) {
          this.loading = false
          Modal.confirm({
            title: 'Print?',
            content: 'Apakah anda ingin cetak taksasi?',
            okText: 'Yes',
            okType: 'success',
            cancelText: 'No',
            onOk: async () => {
            // await lou.getDocument('taksasibpkb', res.data.id)
              this.generateDocx(res.data)
              this.$router.go(-1)
            // this.$router.push('/taksasijaminan/bpkb')
            },
            onCancel: () => {
              this.$router.go(-1)
              // this.$router.push('/taksasijaminan/bpkb')
              console.log('Cancel')
            },
          })
        } else {
          this.loading = false
          lou.shownotif('error', 'Something wrong!')
        }
      } else {
        this.loading = false
        errordatapenilaian.forEach(element => {
          lou.shownotif('error', 'Data pada baris ' + element.baris + ' dengan nilai ' + element.value + ', Harap diperbaiki!', 'error')
        })
      }
    },
    async onUpload(name, imagedata, element) {
      this.uploadvalue = 0
      // var returnnya = ''
      const storageRef = firebase
        .storage()
        .ref('taksasi/bpkb/' + name)
        .put(imagedata)
      storageRef.on(
        'state_changed',
        (snapshot) => {
          this.uploadvalue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (error) => {
          console.log('error.message', error.message)
        },
        () => {
          this.uploadvalue = 100
          // console.log('url', url)
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.urlGambar.push({ key: element.from, link: url })
            if (this.urlGambar.length === this.input.uploadbpkb.length) {
              this.simpan()
            }
            return url
          })
        },
      )
      // returnnya = await storageRef.snapshot.ref.getDownloadURL()
      // console.log('returnnya', returnnya)
      // return returnnya
    },
    setData() {
      // console.log('this.editdata', this.editdata)
      // console.log('this.editdata.tglberlakustnk', this.editdata.tglberlakustnk)
      this.input.datapemohon.nomor_registrasi.key = this.editdata.kode_pemohon
      this.input.datapemohon.tanggal_permohonan = this.editdata.pemohon.tglregister
      this.input.datapemohon.jumlah_kredit = this.editdata.pemohon.plafond
      this.input.datapemohon.jangka_waktu = this.editdata.pemohon.lama
      this.input.datapemohon.nama = this.editdata.pemohon.nama
      this.input.datapemohon.alamat = this.editdata.pemohon.alamat
      this.input.datapemohon.jenis_pekerjaan = this.editdata.pemohon.pekerjaan
      this.input.datapemohon.nama_perusahaan = this.editdata.pemohon.pekerjaan
      this.datatablenilaipasar = this.editdata.details_bpkb
      this.input.stnk = moment(this.editdata.tglberlakustnk, 'YYYY-MM-DD')
      this.input.nomor_taksasi = this.editdata.nomortaksasi
      this.input.datakendaraan.nomor_bpkb = this.editdata.nomorbpkb
      this.input.datakendaraan.atas_nama_di_bpkb = this.editdata.atasnamabpkb
      this.input.datakendaraan.nama_pemilik_sekarang = this.editdata.namapemiliksekarang
      this.input.datakendaraan.alamat_sekarang = this.editdata.alamatsekarang
      this.input.datakendaraan.no_polisi = this.editdata.nopolisi
      this.input.datakendaraan.merk = this.editdata.merk
      this.input.datakendaraan.jenis = this.editdata.jenis
      this.input.datakendaraan.type = this.editdata.tipe
      this.input.datakendaraan.tahun_pembuatan = this.editdata.tahunpembuatan
      this.input.datakendaraan.isi_silinder = this.editdata.isisilinder
      this.input.datakendaraan.jumlah_roda = this.editdata.jmlroda
      this.input.datakendaraan.nomor_rangka = this.editdata.norangka
      this.input.datakendaraan.nomor_mesin = this.editdata.nomesin
      this.input.datakendaraan.bahan_bakar = this.editdata.bahanbakar
      this.input.datakendaraan.warna = this.editdata.warna
      this.input.datakendaraan.perlengkapan = this.editdata.perlengkapan
      this.input.datakendaraan.asuransi = this.editdata.asuransi
      this.input.datakendaraan.kondisi_kendaraan = this.editdata.kondisikendaraan
      this.input.penilaian.nilai_rata_pasar = this.editdata.nilaipasartatatata
      this.input.penilaian.penyusutan = this.editdata.penyusutan
      this.input.penilaian.nilai_taksasi = this.editdata.nilaibarang
      this.input.kesimpulan.nilai_taksasi = this.editdata.nilaitaksasi
      this.input.kesimpulan.presentase_plafond_kredit = this.editdata.presentaseplafondkredit
      this.input.kesimpulan.maksimal_plafond_kredit = this.editdata.maksimalplafondkredit
      this.input.analisa_yuridis.analisa = this.editdata.analisa
      this.image.bpkbpemilik = this.editdata.bpkbpemilik === '' ? null : this.editdata.bpkbpemilik
      this.image.bpkbkendaraan = this.editdata.bpkbkendaraan === '' ? null : this.editdata.bpkbkendaraan
      this.image.jaminandepan = this.editdata.jaminandepan === '' ? null : this.editdata.jaminandepan
      this.image.jaminankanan = this.editdata.jaminankanan === '' ? null : this.editdata.jaminankanan
      this.image.jaminankiri = this.editdata.jaminankiri === '' ? null : this.editdata.jaminankiri
      this.image.jaminanbelakang = this.editdata.jaminanbelakang === '' ? null : this.editdata.jaminanbelakang
      this.datatablenilaipasar = []
      this.datatablenilaipasar = this.editdata.details_bpkb
    },
    tambahnilaipasar() {
      if (
        this.input.fortable.jenis !== '' &&
        this.input.fortable.value !== ''
      ) {
        var uniq = 'id' + new Date().getTime()
        this.datatablenilaipasar.push({
          key: uniq,
          jenis: this.input.fortable.jenis,
          value: this.input.fortable.value !== '' ? parseInt(this.input.fortable.value) : '',
          keterangan: this.input.fortable.keterangan,
        })
        this.input.fortable.value = ''
        this.input.fortable.keterangan = '-'
        this.showModal()
      } else {
        this.showModal()
      }
    },
    async fetchUser(value) {
      // console.log('fetchinguser', value)
      if (value !== '') {
        this.lastFetchId += 1
        this.data = []
        this.fetching = true
        var response = await lou.customUrlGet2('pengajuankredit?limit=10&kode=' + value)
        if (response) {
          if (response.data.length !== 0) {
            this.data = response.data
          } else {
            this.data = []
            lou.shownotif('Not Found!', 'Data not found!')
            this.fetching = false
          }
        }
      } else {
        console.log('Empty: -')
      }
    },
    handleChange(value) {
      var backup = this.$g.clone(this.data)
      var target = backup.find((x) => x.kode === value.key)
      if (target) {
        this.input.datapemohon.nama = target.nama
        this.input.datapemohon.alamat = target.alamat
        this.input.datapemohon.jenis_pekerjaan = target.pekerjaan
        this.input.datapemohon.nama_perusahaan = target.pekerjaan
        this.input.datapemohon.tanggal_permohonan = moment(
          target.tglregister,
          'YYYY-MM-DD',
        )

        this.input.datapemohon.jumlah_kredit = target.plafond
        this.input.datapemohon.jangka_waktu = target.lama
      }
      Object.assign(this, {
        value,
        data: [],
        fetching: false,
      })
    },
    countRataRata() {
      if (this.datatablenilaipasar.length !== 0) {
        var grandTotal = 0
        var totalSumValue = 0
        this.datatablenilaipasar.forEach((element) => {
          totalSumValue += parseInt(element.value)
        })
        grandTotal = totalSumValue / this.datatablenilaipasar.length
        this.input.penilaian.nilai_rata_pasar = grandTotal
      } else {
        this.input.penilaian.nilai_rata_pasar = 0
      }
      this.penyusutan()
    },
    penyusutan() {
      // console.log('this.input.penilaian.penyusutan', this.input.penilaian.penyusutan)
      var penyusutan =
        (parseInt(this.input.penilaian.nilai_rata_pasar) *
          parseInt(this.input.penilaian.penyusutan)) /
        100
      var grandTotal =
        parseInt(this.input.penilaian.nilai_rata_pasar) - parseInt(penyusutan)
      this.input.penilaian.nilai_taksasi = grandTotal
      this.input.kesimpulan.nilai_taksasi = grandTotal
      // console.log('grandTotal', grandTotal)
    },
    countKesimpulan() {
      // console.log('this.input.penilaian.penyusutan', this.input.penilaian.penyusutan)
      var persentase = parseInt(this.input.kesimpulan.presentase_plafond_kredit) / 100
      var grandTotal = parseInt(this.input.kesimpulan.nilai_taksasi) * persentase
      var parsedIntGrandTotal = parseFloat(grandTotal).toFixed(0)
      this.input.kesimpulan.maksimal_plafond_kredit = parsedIntGrandTotal
      // console.log('grandTotal', grandTotal)
      // if (grandTotal !== parsedIntGrandTotal) {
      // }
    },
    showModal() {
      var column = [
        {
          title: 'Jenis',
          className: 'Jenis',
          dataIndex: 'jenis',
          scopedSlots: {
            customRender: 'jenis',
          },
        },
        {
          title: 'Nilai',
          className: 'Nilai',
          dataIndex: 'value',
          scopedSlots: {
            customRender: 'value',
          },
        },
        {
          title: 'Keterangan',
          className: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: {
            customRender: 'keterangan',
          },
        },
      ]
      this.$refs.detailmodal.showModal(
        'nilai pasar',
        column,
        this.datatablenilaipasar,
        'datatablenilaipasar',
      )
    },
    loadFile(url, callback) {
      PizZipUtils.getBinaryContent(url, callback)
    },
    async generateDocx(record) {
      NProgress.start()
      // lou.shownotif('Word Generator!', 'Generating Word File!', 'info')
      // var storageRef = ''
      // await firebase
      //   .storage()
      //   .ref('taksasi/template/' + this.user.sandibpr + 'TaksasiBPKB_Template.docx').getDownloadURL().then((url) => {
      //     // `url` is the download URL
      //     console.log(url)
      //     storageRef = url
      //   // eslint-disable-next-line handle-callback-err
      //   }).catch(async (error) => {
      //     // Handle any errors
      //     var defaultTemplateLink = await firebase
      //       .storage()
      //       .ref('taksasi/template/TaksasiBPKB_Template.docx').getDownloadURL()
      //     storageRef = defaultTemplateLink
      //     // switch (error.code) {
      //     //   case 'storage/object-not-found':
      //     //     // File doesn't exist
      //     //     lou.shownotif("File doesn't exist")
      //     //     break

      //     //   case 'storage/unauthorized':
      //     //     // User doesn't have permission to access the object
      //     //     lou.shownotif("User doesn't have permission to access the object")
      //     //     break

      //     //   case 'storage/canceled':
      //     //     // User canceled the upload
      //     //     lou.shownotif('User canceled the upload')
      //     //     break

      //     //   case 'storage/unknown':
      //     //     // Unknown error occurred, inspect the server response
      //     //     lou.shownotif('Unknown error occurred, inspect the server response')
      //     //     break
      //     //   default:
      //     //     break
      //     // }
      //   })
      // console.log('storageRef', storageRef)
      var dataSettings = []
      var res = await lou.customUrlGet('jaminan/settings', true, true)
      if (res) {
        dataSettings = res.data
      }
      var that = this
      this.loadFile(
        'https://apilbbprs.heasoft.com/word/600767TaksasiBPKB_Template.docx',
        function (error, content) {
          var dataset = {
            ...record,
            datasettings: dataSettings,
            penilaian: [],
            imgBpkbPemilik:
                record.bpkbpemilik === '' || record.bpkbpemilik === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.bpkbpemilik,
            imgBpkbKendaraan:
                record.bpkbkendaraan === '' || record.bpkbkendaraan === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.bpkbkendaraan,
            imgJaminanDepan:
                record.jaminandepan === '' || record.jaminandepan === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.jaminandepan,
            imgJaminanKanan:
                record.jaminankanan === '' || record.jaminankanan === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.jaminankanan,
            imgJaminanKiri:
                record.jaminankiri === '' || record.jaminankiri === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.jaminankiri,
            imgJaminanBelakang:
                record.jaminanbelakang === '' || record.jaminanbelakang === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.jaminanbelakang,
          }
          var arrpenilaian = record.details_bpkb
          var jumlahNilai = 0
          arrpenilaian.forEach((isiNilai) => {
            jumlahNilai += isiNilai.value

            isiNilai.value = isiNilai.value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            dataset.penilaian.push(isiNilai)
          })

          dataset.jumlahNilai = jumlahNilai
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

          dataset.namapemohon = record.pemohon.nama
          dataset.alamatpemohon = record.pemohon.alamat
          dataset.pekerjaanpemohon = record.pemohon.pekerjaan
          dataset.tglSekarang = moment().locale('id').format('DD MMMM YYYY')
          dataset.namaUserLogin = that.user.name

          // Format curency
          dataset.nilaibarang = 100 - parseFloat(record.penyusutan)
          dataset.nilaipasarratarata = lou.curency(record.nilaipasarratarata)
          dataset.nilaitaksasi = lou.curency(record.nilaitaksasi)
          dataset.maksimalplafondkredit = lou.curency(record.maksimalplafondkredit)
          dataset.jumlahnilai = lou.curency(jumlahNilai)

          // Settings Mengubah Disini
          var itarget = dataset.datasettings.findIndex(x => x.kode === 'namaKepalaBagian')
          dataset.namaKepalaBagian = dataset.datasettings[itarget].keterangan
          var opts = {}
          opts.centered = false
          opts.getImage = function (tagValue, tagName) {
            // console.log('tagValue', tagValue)
            return new Promise(function (resolve, reject) {
              PizZipUtils.getBinaryContent(tagValue, function (error, content) {
                // console.log('content', content)
                if (error) {
                  // console.log('error', error)
                  return reject(error)
                }
                return resolve(content)
              })
            })
          }
          opts.getSize = function (img, tagValue, tagName) {
            // return new Promise(function (
            //   resolve,
            //   reject,
            // ) {
            //   const image = new Image()
            //   image.src = tagValue
            //   image.onload = function () {
            //     // console.log('image.width', image.width)
            //     // console.log('image.height', image.height)
            //     resolve([
            //       image.width * (85 / 100),
            //       image.height * (85 / 100),
            //     ])
            //   }
            //   image.onerror = function (e) {
            //     console.log(
            //       'img, tagValue, tagName : ',
            //       img,
            //       tagValue,
            //       tagName,
            //     )
            //     alert(
            //       'An error occured while loading ' +
            //       tagValue,
            //     )
            //     reject(e)
            //   }
            // })
            // FOR FIXED SIZE IMAGE :
            return [345.6, 460.8]
          }
          // eslint-disable-next-line no-unused-vars
          var imageModule = new ImageModule(opts)
          if (error) {
            throw error
          }
          const zip = new PizZip(content)
          const doc = new Docxtemplater()
            .loadZip(zip)
            .attachModule(imageModule)
            .compile()

          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.renderAsync(dataset).then(() => {
            console.log('ready')
            // doc.render()
            const out = doc.getZip().generate({
              type: 'blob',
              mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
            lou.shownotif('Generator success!', 'Generate word success without problem!', 'success')
            NProgress.done()
            // Output the document using Data-URI
            saveAs(out, 'Taksasi BPKB ' + record.pemohon.nama + '_' + record.nomorbpkb + '.docx').then(() => {
            })
          })
        },
      )
    },
    handleSearch(value) {
      fetch(value, (data) => (this.data = data))
    },
    selectFile(ref) {
      this.$refs[ref].click()
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style>
[data-kit-theme="default"] .ant-card-body {
  padding: 14px !important;
}
[data-kit-theme="dark"] .ant-card-body {
  padding: 10px !important;
}
</style>
